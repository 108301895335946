import React from 'react';
import styled from "styled-components";
import Logo from "../images/logo.png";

/*const Terms = () => (
    <StyledTerms>
        <div className="terms__logo">
            <img src={Logo} />
        </div>
            <b>PATIENTENINFORMATION ZUM DATENSCHUTZ</b>
            <br /><br />

            Sehr geehrte Patientin, sehr geehrter Patient,der Schutz Ihrer personenbezogenen Daten ist uns wichtig. Nach der EU-Datenschutz-Grundverordnung (DSGVO) sind wir verpflichtet, Sie darüber zu informieren, zu welchem Zweck unsere Praxis Daten erhebt, speichert oder weiterleitet. Der Information können Sie auch entnehmen, welche Rechte Sie in puncto Datenschutz haben.
            <br /><br />

            VERANTWORTLICHKEIT FÜR DIE DATENVERARBEITUNG
            <br /><br />
            Verantwortlich für die Datenverarbeitung ist:<br />
            Dr. med. Karsten Brauns<br />
            Lohstraße 4<br />
            31785 Hameln<br />
            Tel. 05151-41424<br />
            mail@dr-brauns.de
            <br /><br />

            2. ZWECK DER DATENVERARBEITUNG<br />

            Die Datenverarbeitung erfolgt aufgrund gesetzlicher Vorgaben, um den Behandlungsvertrag zwischen Ihnen und Ihrem Arzt und die damit verbundenen Pflichten zu erfüllen.
            Hierzu verarbeiten wir Ihre personenbezogenen Daten, insbesondere Ihre Gesundheitsdaten. Dazu zählen Anamnesen, Diagnosen, Therapievorschläge und Befunde, die wir oder andere Ärzte erheben. Zu diesen Zwecken können uns auch andere Ärzte oder Psychotherapeuten, bei denen Sie in Behandlung sind, Daten zur Verfügung stellen (z.B. in Arztbriefen).
            Die Erhebung von Gesundheitsdaten ist Voraussetzung für Ihre Behandlung. Werden die notwendigen Informationen nicht bereitgestellt, kann eine sorgfältige Behandlung nicht erfolgen.
            <br /><br />

            3. EMPFÄNGER IHRER DATEN<br />

            Wir übermitteln Ihre personenbezogenen Daten nur dann an Dritte, wenn dies gesetzlich erlaubt ist oder Sie eingewilligt haben.
            Empfänger Ihrer personenbezogenen Daten können vor allem andere Ärzte / Psychotherapeuten, Kassenärztliche Vereinigungen, Krankenkassen, der Medizinische Dienst der Krankenversicherung, Ärztekammern und privatärztliche Verrechnungsstellen sein.
            Die Übermittlung erfolgt überwiegend zum Zwecke der Abrechnung der bei Ihnen erbrachten Leistungen, zur Klärung von medizinischen und sich aus Ihrem Versicherungsverhältnis ergebenden Fragen. Im Einzelfall erfolgt die Übermittlung von Daten an weitere berechtigte Empfänger.
            <br /><br />

            4. SPEICHERUNG IHRER DATEN<br />

            Wir bewahren Ihre personenbezogenen Daten nur solange auf, wie dies für die Durchführung der Behandlung erforderlich ist.
            Aufgrund rechtlicher Vorgaben sind wir dazu verpflichtet, diese Daten mindestens 10 Jahre nach Abschluss der Behandlung aufzubewahren. Nach anderen Vorschriften können sich längere Aufbewahrungsfristen ergeben, zum Beispiel 30 Jahre bei Röntgenaufzeichnungen laut Paragraf 28 Absatz 3 der Röntgenverordnung.
            <br /><br />

            5. IHRE RECHTE<br />

            Sie haben das Recht, über die Sie betreffenden personenbezogenen Daten Auskunft zu erhalten. Auch können Sie die Berichtigung unrichtiger Daten verlangen.
            Darüber hinaus steht Ihnen unter bestimmten Voraussetzungen das Recht auf Löschung von Daten, das Recht auf Einschränkung der Datenverarbeitung sowie das Recht auf Datenübertragbarkeit zu.
            Die Verarbeitung Ihrer Daten erfolgt auf Basis von gesetzlichen Regelungen. Nur in Ausnahmefällen benötigen wir Ihr Einverständnis. In diesen Fällen haben Sie das Recht, die
            Einwilligung für die zukünftige Verarbeitung zu widerrufen.
            Sie haben ferner das Recht, sich bei der zuständigen Aufsichtsbehörde für den Datenschutz zu beschweren, wenn Sie der Ansicht sind, dass die Verarbeitung Ihrer personenbezogenen Daten nicht rechtmäßig erfolgt.
            Die Anschrift der für uns zuständigen Aufsichtsbehörde lautet:  Landesbeauftragter für den Datenschutz Niedersachsen, Prinzenstr. 5, 30159 Hannover
            <br /><br />
            
            6. RECHTLICHE GRUNDLAGEN<br />
            
            Rechtsgrundlage für die Verarbeitung Ihrer Daten ist Artikel 9 Absatz 2 lit. h) DSGVO in Verbindung mit Paragraf 22 Absatz 1 Nr. 1 lit. b) Bundesdatenschutzgesetz.7. DatenerFASSUNG auf der PRAXIS-Website
            <br /><br />
            A)  durch die Website-Inhaber
            <br /><br />
            Auf der Website selbst werden durch uns keine persönlichen Daten erhoben. Sie dient ausschließlich der Information und der Möglichkeit der Online-Terminvereinbarung sowie der Bestellung von Folgeverordnungen (s. unten).
            <br /><br />
            B)  durch den Webhosting-Provider
            <br /><br />
            Tracking und Logging sind beim Provider standardmäßig aktiviert.
            Die hierbei gewonnenen Daten werden entweder durch einen Pixel oder durch ein Logfile ermittelt. Zum Schutz von personenbezogenen Daten werden keine Cookies verwendet. Der Provider speichert keine personenbezogenen Daten von Websitebesuchern, damit keine Rückschlüsse auf die einzelnen Besucher gezogen werden können. Es werden folgende Daten erhoben:
            Referrer (zuvor besuchte Webseite)
            Angeforderte Webseite oder Datei
            Browsertyp und Browserversion
            Verwendetes Betriebssystem
            Verwendeter Gerätetyp
            Uhrzeit des Zugriffs
            IP-Adresse in anonymisierter Form (wird nur zur Feststellung des Orts des Zugriffs verwendet)
            Diese  Daten werden ausschließlich zur statistischen Auswertung und zur technischen Optimierung des Webangebots erhoben.
            Es werden keine Daten an Dritte weitergegeben.
            <br /><br />
            C)  im Rahmen der Onlineterminvereinbarung und Folgerezeptbestellung
            <br /><br />
            Durch Klicken auf den Link zur Onlineterminvereinbarung verlassen Nutzer unsere Website und werden auf die die hierfür verwendeten Server der CGM LIFE eSERVICES weitergeleitet. Auf deren Inhalte haben wir keinen Einfluss und können dafür keine Gewähr übernehmen. Zu Ihrer Information vor der Weiterleitung zitieren wir im Folgenden auszugsweise Erklärungen der CompuGroup Medical Deutschland AG zum Datenschutz:
            „Voraussetzung für die Nutzung der CGM LIFE eSERVICES ist die Einrichtung eines CGM LIFE Kontos. ... Das CGM LIFE Konto (auch CGM LIFE Account genannt) ist die Gesundheitsakte von CGM LIFE und ein Angebot der CGM. Die Gesundheitsakte CGM LIFE Konto ermöglicht die strukturierte und verschlüsselte Speicherung und den Austausch medizinischer Daten. Darüber hinaus beinhaltet das CGM LIFE Konto den sicheren Zugang und zentralen Speicher zu verschie- denen Online-Gesundheitsdiensten. Für die Nutzung der verschiedenen Online-Gesundheitsdienste können zusätzliche, separate Datenschutzerklärungen gelten. Die Nutzung des CGM LIFE Kontos durch einen Online-Gesundheitsdienst setzt dabei jeweils die gesonderte Zustimmung des Nutzers sowie die Annahme gesonderter Nutzungsbedingungen des Online- Gesundheitsdiensts voraus, durch die der Umfang der Nutzung der im LIFE Konto gespeicherten Daten geregelt wird. Der Nutzer verfügt somit jederzeit über die Hoheit der im LIFE Konto gespeicherten Daten.
            Der Nutzer muss für die Erstellung seines LIFE Kontos Zugangsdaten auswählen. Die Gesundheitsdaten werden verschlüsselt gespeichert und können nur mit den persönlichen Zugangsdaten entschlüsselt werden. Um einen Zugriff von CGM auf die verschlüsselt gespeicherten Gesundheitsdaten auszuschließen, wird CGM die Zugangsdaten nicht speichern. ...
            Die CGM speichert bei der Verwendung der angebotenen Produkte / Dienste folgende Arten von Daten auf ihrem Server:
            <ul>
                <li>Vertrags- und Registrierungsdaten</li>
                <li>Medizinische Daten</li>
                <li>Daten zum technischen Betrieb</li>
                <li>Anonymisierte Daten</li>
                >
                verpflichten uns gemäß Datenschutzgesetz, sämtliche Vertragsdaten, sämtliche Protokolldaten und sämtliche Daten zum technischen Betrieb nach Kündigung Ihres Vertrages zu löschen.
            Hierbei sind wir jedoch gesetzlich verpflichtet, handels- und steuerrechtliche Aufbewahrungsfristen zu beachten, die über die Dauer des Vertragsverhältnisses hinausgehen können. Daten zum technischen Betrieb werden nur so lange vorgehalten, wie es technisch notwendig ist, spätestens jedoch nach Kündigung Ihres Vertrages gelöscht. ...
            Die medizinischen Daten unterliegen neben den Sicherheitsanforderungen der Datenschutzgesetze, zusätzlich strengen Auflagen aus dem Strafgesetzbuch (StGB) sowie den Sozialgesetzbüchern (SGB).
            Die im Rahmen der Nutzung von Produkten und Diensten der Produktfamilie CGM LIFE anfallenden medizinischen Daten werden verschlüsselt übertragen und verschlüsselt und getrennt von allen anderen Datentypen im CGM LIFE Konto des Nutzers gespeichert. Die dabei von der CGM verwendete patentierte CGM-LIFE-Technologie schützt Ihre medizinischen Daten auf diese Weise vor Manipulation, Diebstahl und Beschlagnahme. (Detaillierte Informationen hierzu finden Sie unter www.cgm-life.de). Weder Mitarbeiter der CGM oder Mitarbeiter anderer Unternehmen, die mit der Speicherung Ihrer medizinischen Daten befasst sein könnten, noch Dritte erhalten Zugriff oder Einsicht in Ihre medizinischen Daten. Einzig Sie selbst und ausdrücklich durch Sie autorisierte Personen bzw. Institutionen sind in der Lage, medizinische Daten abzurufen, zu entschlüsseln, zu lesen oder weiter zu geben.
            Die medizinischen Daten werden auf dem CGM Server in Deutschland gespeichert und werden direkt nach der Kündigung der Geschäftsbeziehung gelöscht.“
            <br /><br />
            D)  Link zum App Store (Apple Inc.)<br /><br />
            Durch Klicken auf den Link zum App Store verlassen Sie unsere Website und damit unseren Einflussbereich und werden möglicherweise auf einen Server außerhalb der Europäischen Union weitergeleitet. Der Link dient ausschließlich dem Zweck, es der Nutzerin zu erleichtern, die mobile App für die Onlineterminvereinbarung und Folgerezeptbestellung aufzufinden und ggf. auf ihrem Mobilgerät zu installieren. Da wir auf den Inhalt der externen Website keinen Einfluss haben, kann für die Nutzung dieses Service von uns keinerlei Gewähr und Verantwortung übernommen werden. Für die dort bereitgestellten Inhalte ist der App Store-Betreiber verantwortlich. Wir empfehlen daher, sich vor Nutzung des Links mit der Datenschutzerklärung des App Store-Betreibers auseinanderzusetzen. Im Zweifelsfall können Onlineterminvereinbarung und Rezeptbestellung auch ohne die App über den normalen Browser vorgenommen werden.
            <br /><br />
            Sollten Sie Fragen oder Anregungen haben, können Sie sich gern an uns wenden.<br /><br />
            i>Ihr Praxisteam</i></b>
            Terms>
        
   
const StyledTerms = styled.div`
  padding: 2rem 3rem 3rem;
  
  .terms__logo img {
          width: 200px;
          margin-bottom: 1rem;
          margin-left: -0.5rem;
          
*/         
  
const Terms = () => {
    React.useEffect(
        () => {
            const a = document.createElement('a');
            a.href = '/public/Datenschutzinformation_2023.pdf';
            a.target = '_blank';
            a.click();
        },
        []
    );
    return null;
};

export default Terms;


